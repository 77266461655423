import { modalHandler, shareButton, initializeFacebook } from "sharing_helper"
import { ServiceTabs, TqaSuggestion } from "service_helper"
import { setDatadogRumContext } from "setup_helper"
import { importmapSetup } from "setup"

importmapSetup()

document.addEventListener("DOMContentLoaded", function() {
  ServiceTabs()
  TqaSuggestion()

  document.querySelectorAll("[data-modal-id]").forEach(el => {
    modalHandler(el.dataset.modalId, el.dataset.modalOpenSelector)
  })

  initializeFacebook()
  shareButton()
  setDatadogRumContext("key-page", "service-show")
}, { once: true })
